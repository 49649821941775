<template>
  <div class="contain">
    <div class="title">
      <span></span>
      <p>气溶胶粒子实时数据监测</p>
      <span></span>
    </div>

    <div>
      <div class="carouseldiv">
        <el-carousel :interval="8000" indicator-position="none" height="40px" @change="carouselChange">
          <el-carousel-item v-for="(item,i) in list" :key="i">
            <div class="roomname">
              <div class="header">
                <i class="el-icon-arrow-left"></i>
                <span>{{item.roomName}}</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="data1">
        <div>
          <i>总气溶胶粒子数(个/s)</i>
          <span>{{obj.parOrAll}}</span>
        </div>
        <div class="midd">
          <i>总荧光粒子数(个/s)</i>
          <span>{{obj.bioOrAll}}</span>
        </div>
        <div> <i>生物粒子浓度(个/L)</i>
          <span>{{obj.bioConcentration}}</span>
        </div>

      </div>
      <div class="data2">
        <div class="lizi">
          <div class="sbname">散射光4通道粒子数(个/s)</div>
          <div class="cont">
            <div>
              <i>0.5-1μm</i>
              <span>{{obj.parOrSum1}}</span>
            </div>
            <div>
              <i>1-2.5μm</i>
              <span>{{obj.parOrSum2}}</span>
            </div>
            <div>
              <i>2.5-5μm</i>
              <span>{{obj.parOrSum3}}</span>
            </div>
            <div>
              <i>5-10μm</i>
              <span>{{obj.parOrSum4}}</span>
            </div>
          </div>
        </div>

        <div class="midd">
          <span
            :class="{zc:obj.alarmState==0,swjb:obj.alarmState==1,ywjb:obj.alarmState==2,swjg:obj.alarmState==3}"></span>
        </div>
        <div class="lizi">
          <div class="sbname">荧光4通道粒子数(个/s)</div>
          <div class="cont">
            <div>
              <i>0.5-1μm</i>
              <span>{{obj.bioOrSum1}}</span>
            </div>
            <div>
              <i>1-2.5μm</i>
              <span>{{obj.bioOrSum2}}</span>
            </div>
            <div>
              <i>2.5-5μm</i>
              <span>{{obj.bioOrSum3}}</span>
            </div>
            <div>
              <i>5-10μm</i>
              <span>{{obj.bioOrSum4}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="title1">
        <span>实时警报</span>
      </div>
      <div class="jingbao">
        <div class="header1">
          <span></span>
          <span>绑定设备名称</span>
          <span>运行状态</span>
          <span></span>
        </div>
      </div>
      <div class="all">
        <div class="content">
          <table :style="{'--uploadStyle':length+'s','--animation':animation}">
            <tr v-for="item in state" :key="item">
              <td>{{item.deviceName}}</td>
              <td
                :class="{lixian:item.useTiem===1,zaixian:item.useTiem===2,del:item.useTiem===-1,shui:item.useTiem===3}">
                {{item.useTiem===1? '离线':item.useTiem===-1? '删除':item.useTiem===2? '在线':useTiem===3? '睡眠':''}}</td>

            </tr>
          </table>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { aerosol } from '@/api/aerosol'
export default {
  data () {
    return {
      list: [],
      obj: {},
      state: [],
      animation: 'paused',
      length: 0,
      timer1: null
    }
  },
  created () {
    this.aerosol()
    this.timer1 = setInterval(() => {
      this.aerosol();
    }, 180000);
  },
  methods: {
    async aerosol () {
      let { data } = await aerosol()
      console.log(data);
      this.list = data
      if (this.list.length === 1) {
        this.obj = this.list[0]
        if (this.list[0].statDevice.length > 2) {
          this.state = [...this.list[0].statDevice, ...this.list[0].statDevice]
          this.animation = 'running'
          this.length = this.state.length
        } else {
          this.state = [...this.list[0].statDevice]
          this.animation = 'paused'
          this.length = this.state.length
        }
      }
    },
    carouselChange (i) {
      let arr = this.list.filter((item, index) => index === i)
      this.obj = arr[0]
      if (arr[0].statDevice.length > 2) {
        this.state = [...arr[0].statDevice, ...arr[0].statDevice]
        this.animation = 'running'
        this.length = this.state.length
      } else {
        this.state = [...arr[0].statDevice]
        this.animation = 'paused'
        this.length = this.state.length
      }
    }
  },
  beforeUnmount () {
    clearInterval(this.timer1)
  }
}
</script>

<style lang='scss' scoped>
.contain {
  width: 620px;
  height: 738px;
  background: url("~@/assets/img/5.0/矩形备份 31.png") no-repeat;
  background-size: contain;
  transform: translateX(-13.5%);
  margin-top: 20px !important;
  margin-bottom: 4px;
  padding-top: 16px;
  margin: 0 auto;

  .title {
    display: flex;
    justify-content: center;
    span {
      display: inline-block;
      width: 8px;
      height: 22px;
      background: #f7dd70;
      border: 1px solid #f7dd70;
      border-radius: 2px;
    }
    p {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #08f4da;
      margin-right: 16px;
      margin-left: 16px;
      line-height: 22px;
    }
  }
  .roomname {
    display: flex;
    justify-content: center;
    .header {
      display: flex;
      width: 458px;
      height: 36px;
      background: rgba(8, 244, 218, 0.3);
      border-radius: 5px;
      padding: 0 10px;
      padding-top: 1%;
      margin-top: 2px;
      i {
        opacity: 0.3;
        border: 0px solid #ffffff;
        color: #fff;
        margin-top: 5px;
      }
      span {
        text-align: center;
        flex: 1;
        width: 54px;
        height: 22px;
        font-size: 16px;
        font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
        font-weight: normal;
        color: #ffffff;
        line-height: 25px;
        letter-spacing: 2px;
      }
    }
  }
  .data1 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    div {
      height: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      i {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      span {
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #08f4da;
      }
      &.midd {
        margin: 0 20px;
      }
    }
  }
  .data2 {
    display: flex;
    justify-content: center;
    height: 170px;
    .lizi {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .sbname {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .cont {
        width: 217px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: -10px;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 60px;
          i {
            width: 100px;
            height: 30px;
            background: url("~@/assets/img/5.0/编组 7备份 3.png") no-repeat;
            background-size: contain;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            text-align: center;
            line-height: 30px;
          }
          span {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #08f4da;
            line-height: 23px;
          }
        }
      }
    }
    .midd {
      margin: 28px 20px 0 20px;
      width: 93px;
      height: 129px;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;

        &.zc {
          background: url("~@/assets/img/5.0/编组 4.png") no-repeat;
          background-size: contain;
        }
        &.swjb {
          background: url("~@/assets/img/5.0/编组 3.png") no-repeat;
          background-size: contain;
        }
        &.ywjb {
          background: url("~@/assets/img/5.0/编组 2.png") no-repeat;
          background-size: contain;
        }
        &.swjg {
          background: url("~@/assets/img/5.0/编组.png") no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .title1 {
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
    span {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.jingbao {
  .header1 {
    margin-left: 5%;
    display: flex;
    width: 569px;
    height: 36px;
    background: linear-gradient(
      125deg,
      rgba(19, 250, 239, 0.4) 0%,
      rgba(8, 244, 218, 0) 100%
    );
    border-radius: 5px;
    span:nth-child(1) {
      display: inline-block;
      margin-top: 1.2%;
      margin-left: -0.5%;
      vertical-align: middle;
      width: 8px;
      height: 22px;
      background: #f7dd70;
      border-radius: 3px;
    }
    span:nth-child(2),
    span:nth-child(3) {
      display: inline-block;
      height: 22px;
      font-size: 16px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #ffffff;
      line-height: 34px;
      vertical-align: bottom;
    }
    span:nth-child(2) {
      flex: 1.4;

      margin-left: 3%;
    }
    span:nth-child(3) {
      margin-left: 60%;
      flex: 1;
    }
    span:nth-child(4) {
      display: inline-block;
      margin-top: 1.2%;
      margin-left: -0.5%;
      vertical-align: middle;
      width: 8px;
      height: 22px;
      background: #f7dd70;
      border-radius: 3px;
      margin-left: 3.5%;
    }
  }
}
i {
  font-style: normal;
}
::v-deep .el-carousel {
  width: 76%;
  margin-left: 12%;
}
table {
  width: 100%;
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  animation: move var(--uploadStyle) linear infinite;
  animation-play-state: var(--animation);
  tr {
    height: 43px;
    display: flex;
    td {
      // margin-top: 32px;
      font-size: 16px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
    }
    td:nth-child(1),
    td:nth-child(2) {
      flex: 1;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    td:nth-child(1) {
      text-align: left;
      padding-left: 20px;
    }
    td:nth-child(2) {
      text-align: right;
      padding-right: 10px;
    }
    .lixian {
      color: rgba(255, 186, 77, 1);
    }
    .zaixian {
      color: rgba(58, 242, 156, 1);
    }
    .del {
      color: red;
    }
    .shui {
      color: #08f4da;
    }
  }
}
.all {
  margin-left: 6%;
  height: 130px;
  overflow: hidden;
  .content {
    width: 90%;
    background: rgba(0, 0, 0, 0);
    p {
      position: relative;
      top: 24px;
      left: -33%;
      width: 129px;
      font-size: 16px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #ffffff;
      line-height: 22px;
    }
  }

  @keyframes move {
    0% {
    }

    100% {
      transform: translateY(-50%);
    }
  }
}
table:hover {
  animation-play-state: paused;
}
</style>