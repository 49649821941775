import request from '../utils/request';
// 空气质量平均情况

export const airAvg = () => {
  return request({
    url: '/kanban/robot/airAvg',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
// 气溶胶粒子实时数据监测
export const aerosol = () => {
    return request({
      url: '/kanban/robot/aerosol',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  };
