<template>
  <div>
    <div class="all">
      <div class="content">
        <table :style="{'--uploadStyle':length+'s','--animation':animation}">
          <tr v-for="item in list" :key="item">
            <td>{{item.name}}</td>
            <td>{{item.startTime}}</td>
            <td>{{item.finishTime}}</td>
          </tr>
        </table>

      </div>
    </div>

  </div>

</template>

<script>

export default {
  props: {
    //数据
    list: Array,
    length: Number, //数据长度
    animation: String,//控制动画的停止与否
  },
  data () {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  animation: move var(--uploadStyle) linear infinite;
  animation-play-state: var(--animation);
  tr {
    height: 43px;
    display: flex;
    td {
      // margin-top: 32px;
      font-size: 16px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
    }
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(3) {
      flex: 1;
      text-align: center;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    td:nth-child(2),
    td:nth-child(3) {
      flex: 2;
    }
  }
}
.all {
  margin-left: 6%;
}
table:hover {
  animation-play-state: paused;
}
.content {
  width: 90%;
  background: rgba(0, 0, 0, 0);
  p {
    position: relative;
    top: 24px;
    left: -33%;
    width: 129px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 22px;
  }
  // ::v-deep .el-progress-bar__inner {
  //   background: linear-gradient(270deg, #f7da65 0%, #07ba74 100%);
  // }
  // ::v-deep .el-progress-bar__outer {
  //   background: rgba(255, 255, 255, 0.2);
  // }
  // ::v-deep .el-progress__text {
  //   width: 40px;
  //   font-size: 18px;
  //   font-family: PingFangSC-Medium, PingFang SC;
  //   font-weight: 500;
  //   color: #ffffff;
  //   line-height: 25px;
  // }
}
/* 通过CSS3动画滚动marquee */
@keyframes move {
  0% {
  }

  100% {
    transform: translateY(-50%);
  }
}
</style>